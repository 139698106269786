<!-- highlyCitedArticles -->
<template>
  <div v-loading="loading" class="highlyCitedArticles common-select">
    <el-select
      v-model="chooseValue"
      placeholder="请选择"
      :data-track="`增值服务-文献聚合-高被引文章-${chooseValueLabel}`"
    >
      <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      />
    </el-select>
    <commonTable
      :is-click="false"
      :table-data="tableData"
      :column-data="tableColumn"
      :init-obj="tablePageObj"
      @onChangePageData="
        (pageObj) => {
          getTableData(pageObj)
        }
      "
    ></commonTable>
  </div>
</template>

<script>
import { Get, ProjectConfig } from 'kjqk-commons/src/common/index.js'
const api = ProjectConfig.contentAggregation
import commonTable from '@comp/commonTable'
import { mapState } from 'vuex'
export default {
  name: 'HighlyCitedArticles',
  data() {
    return {
      loading: true,
      chooseValue: 'mag',
      options: [
        {
          value: 'mag',
          label: '本刊'
        },
        {
          value: 'domain',
          label: '本领域'
        }
      ],
      tableData: [],
      tablePageObj: {
        page: 1,
        size: 10
      },
      tableColumn: [
        {
          title: '论文标题',
          prop: 'articleName',
          special: true,
          width: '300px',
          notSortable: true
        },
        {
          title: '刊物名称',
          prop: 'magName',
          notSortable: true
        },
        {
          title: '作者',
          prop: 'author',
          notSortable: true
        },
        {
          title: '机构',
          prop: 'institution',
          notSortable: true
        },
        {
          title: '年份',
          prop: 'year'
        },
        {
          title: '刊期',
          prop: 'issue',
          notSortable: true
        },
        {
          title: '引证次数',
          prop: 'totalCount'
        }
      ]
    }
  },
  props: {},
  watch: {
    chooseValue() {
      if (this.magId) {
        this.getTableData()
      }
    },
    magId() {
      this.getTableData()
    }
  },
  components: { commonTable },
  computed: {
    ...mapState('incrementService', ['magId']),
    chooseValueLabel() {
      let text = ''
      text = this.options.filter((item) => {
        return item.value === this.chooseValue
      })[0].label
      return text
    }
  },
  mounted() {
    if (this.magId) {
      this.getTableData()
    } else {
      this.loading = false
    }
  },
  methods: {
    getTableData(pageParams = {}) {
      const { page = 1, size = 10 } = pageParams
      const params = {
        magId: this.magId,
        page: page - 1,
        size,
        type: this.chooseValue,
        sort: 'totalCount,desc'
      }
      let url = api.GetArticle.url
      Get(url, { params }).then((res) => {
        if (res && res.data && res.data.status === 0) {
          this.loading = false
          const { data } = res.data
          const { content = [], totalElements = 0 } = data
          this.tablePageObj = {
            page,
            size,
            total: totalElements
          }
          this.tableData = content
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.highlyCitedArticles {
  padding: 20px;
  .el-select {
    float: right;
    margin-bottom: 20px;
  }
}
</style>
